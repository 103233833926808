export const plutoGiftFaqs = {
  gift: [
    {
      question: "How is the gift created?",
      answer:
        "Pluto curates top brands and experiences (suggested) from 500+ partner brands, personalised based on the details you provide about the receiver.",
    },
    {
      question: "How do I share this gift?",
      answer:
        "Once the gift balance is added to the gift, you'll get a shareable link to send via WhatsApp or any messaging app.",
    },
    {
      question: "How is the payment made? Is it secure?",
      answer: "You can pay securely via UPI and Credit Card.",
    },
    {
      question: "Is there an expiry date for the gift?",
      answer: "No, this gift never expires.",
    },
    {
      question: "Can I edit this gift?",
      answer:
        "Yes, tap 'Edit' at the top-right of the screen to personalise the gift with a message and brand selection.",
    },
    {
      question: "Can I schedule this gift?",
      answer: "No, but you can share the link whenever the time is right.",
    },
    {
      question: "Are there any extra charges?",
      answer:
        "No extra charges! You only pay the amount you load as the gift balance.",
    },
  ],
  giftBalance: [
    {
      question: "What is gift balance?",
      answer:
        "Gift balance is the amount you load for the receiver to choose products or experiences from the included brands.",
    },
    {
      question: "How much gift balance should I add?",
      answer:
        "Love has no price tag, and neither do Pluto’s gifts. Top up any amount between ₹100 and ₹50,000.",
    },
    {
      question: "How does the receiver use the gift balance?",
      answer:
        "The receiver can use the gift balance to generated gift cards of the included brands, which can be used to pay for their choice of products and experiences.",
    },
    {
      question: "Does the gift balance expire?",
      answer: "No, the gift balance never expires.",
    },
    {
      question: "Can I modify the gift balance once balance is loaded?",
      answer:
        "Once you’ve paid and loaded the gift balance, it cannot be changed.",
    },
    {
      question: "Is the entire gift balance passed to the receiver?",
      answer:
        "Yes, the full gift balance goes to the receiver for them to enjoy.",
    },
  ],
  receiverExperience: [
    {
      question: "How does the receiver use this gift?",
      answer:
        "1. Once shared, the receiver can access the gift balance and the brands included. 2. They can use the gift balance to generate gift card(s). 3. With the gift card, they can pay for their choice of products or experiences from the included brands",
    },
    {
      question:
        "Does the receiver have to split the gift balance equally among the brands included?",
      answer:
        "The receiver can use the gift balance however they like across the brands included in the gift",
    },
    {
      question:
        "Can the receiver only access the products / experiences suggested in the gift?",
      answer:
        "No, the receiver can use the gift balance to pay for their choice of products/experiences however they like, from the brands included in the gift.",
    },

    {
      question: "What if the receiver faces an issue with the gift?",
      answer:
        "While we ensure a seamless experience, if the receiver faces any issues, hassle-free customer support is always at their fingertips.",
    },
  ],
};
